<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="临时排班"/>
        <div style="margin-top: 20px">
            <div class="search">
                <el-input v-model="keywords" size="small" style="width: 20%"></el-input>
                <el-button type="primary" plain size="small" style="margin-left:10px ">检索</el-button>
            </div>
            <div>
                <el-button type="primary" size="small" @click="handleAdd">新建排班</el-button>
            </div>
            <el-table stripe :data="table.data" style="width: 100%;margin-top:10px" border>
                <el-table-column type="index" width="50" align="center"/>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :formatter="v.formatter"
                                 :key="idx">
                </el-table-column>
                <el-table-column align="center" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row)"
                                   size="small"
                                   type="warning"
                                   icon="el-icon-edit">
                        </el-button>
                        <el-button @click="handleDel(scope.row)"
                                   size="small"
                                   type="danger"
                                   icon="el-icon-delete"
                        >
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div style="text-align: center;margin: 20px" v-if="table.total > table.pageSize">
                <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                               :total="table.total" :page-size="table.pageSize"
                               @current-change="handleCurrentChange"
                               @size-change="handleSizeChange"/>
            </div>
        </div>
        <el-dialog
            title="临时排班"
            :visible.sync="dialogVisible"
            width="50%"
            center
        >
            <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="form">
                <el-form-item label="手术医生">
                    <el-select v-model="form.realname" placeholder="请选择">
                        <el-option
                            v-for="item in doctorList"
                            :key="item.id"
                            :label="item.realname"
                            :value="item.realname">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手术室">
                    <el-checkbox-group v-model="form.area">
                        <el-checkbox :label="w" v-for="(w, i) in areaList" :key="i">{{w.name}}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="日期" prop="dates">
                    <el-button @click="uploadTime">
                        选择日期
                        <el-date-picker class="time-date-picker" propper-class="time-panel-picker" ref="tiemPick"
                                        type="dates" value-format="yyyy-MM-dd" v-model="form.dates"
                                        placeholder="选择一个或多个日期"/>
                    </el-button>
                    <div>
                        <el-tag v-for="(t,i) in form.dates" :key="i">{{t}}</el-tag>
                    </div>
                </el-form-item>
                <el-form-item label="时间点">
                    <el-tag
                        :key="tag"
                        v-for="tag in form.times"
                        closable
                        :disable-transitions="false"
                        @close="handleClose(tag)">
                        {{tag}}
                    </el-tag>
                    <el-input
                        class="input-new-tag"
                        v-if="inputVisible"
                        v-model="inputValue"
                        ref="saveTagInput"
                        size="small"
                        @keyup.enter.native="handleInputConfirm"
                        @blur="handleInputConfirm"
                    >
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加时间点</el-button>
                </el-form-item>
                <el-form-item label="备注" prop="desc">
                    <el-input type="textarea" v-model="form.desc"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetForm('form')">重 置</el-button>
                <el-button type="primary" @click="submitForm('form')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            function datesFormatter(t) {
                return t.dates.join(',')
            }
            function timesFormatter(t) {
                return t.times.join(',')
            }
            return {
                inputVisible: false,
                inputValue: '',
                areaList:[],
                doctorList:[],
                table: {
                    columns: [
                        {title: '医生', field: 'realname', width: ''},
                        {title: '日期', field: 'dates', width: '', formatter: datesFormatter},
                        {title: '时间', field: 'times', width: '', formatter: timesFormatter},
                        {title: '备注', field: 'desc', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
                keywords: '',
                dialogVisible: false,
                form: {
                    realname: '',
                    area: [],
                    dates: [],
                    times: [],
                    desc: ''
                },
                rules: {
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'}
                    ],
                    dates: [
                        {required: true, message: '请选择日期', trigger: 'change'}
                    ],
                }
            };
        },
        methods: {
            handleClose(tag) {
                this.form.times.splice(this.form.times.indexOf(tag), 1);
            },
            showInput() {
                this.inputVisible = true;
                // eslint-disable-next-line no-unused-vars
                this.$nextTick(_ => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },
            handleInputConfirm() {
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.form.times.push(inputValue);
                }
                this.inputVisible = false;
                this.inputValue = '';
            },
            uploadTime() {
                console.log(this.$refs)
                // if (this.$refs.tiemPick.$el)
                this.$refs.tiemPick.focus();
                // document.querySelector('.time-date-picker').querySelector('input').focus()
            },
            handleAdd() {
                this.form = {realname: '', area:[], dates: [], times:[],  desc: ''}
                this.dialogVisible = true
            },
            handleUpdate(row) {
                this.dialogVisible = true
                this.form = row
                // 替换对象
                this.areaList.forEach((a)=>{
                    for (let i = 0; i < this.form.area.length; i++) {
                        if (JSON.stringify(a) == JSON.stringify(this.form.area[i])) {
                            this.form.area[i] = a
                        }
                    }
                })
            },
            async handleDel(row) {
                const resp = await this.$http.delete(`/surgery/overtime/${row.ID}`)
                console.log(resp)
                this.$message.success("删除成功")
                this.getData()
            },
            async submitForm(formName) {
                const valid = await this.$refs[formName].validate().then(res => res).catch(error => error);
                if (!valid) {
                    return
                }
                console.log(this.form)
                let resp
                if (this.form.ID) {
                    resp = await this.$http.put(`/surgery/overtime/${this.form.ID}`, this.form)
                } else {
                    resp = await this.$http.post(`/surgery/overtime/`, this.form)
                }
                if (resp.data.code == 200) {
                    this.$message.success("保存成功！")
                    this.dialogVisible = false
                } else {
                    this.$message.error(resp.data.msg)
                }
                this.getData()
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            async initData(){
                const al = await this.$http.get('/surgery/area/')
                this.areaList = al.data.data
                const dl = await this.$http.get('/auth/doctor')
                this.doctorList = dl.data.data

                this.getData()
            },
            async getData() {
                const resp = await this.$http.get(`/surgery/overtime/`)
                this.table.data = resp.data.data
            }
        },
        mounted() {
            this.initData()
        }
    }
</script>

<style lang="scss" scoped>
    .search {
        text-align: center;
    }

    .form {
        margin-top: 20px;
    }

    .time-date-picker {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 0;
    }

    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
</style>
